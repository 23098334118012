import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllBooks,
  getAllPublication,
  getCurrentPublication,
} from 'src/api/publication';

type TPayload = {
  page?: number;
  type?: string;
};

export const getAllPublicationThunk = createAsyncThunk(
  'getAllPublication',
  async (payload?: TPayload) => {
    try {
      const { data: publicationAll } = await getAllPublication(
        payload?.page,
        payload?.type,
      );

      return publicationAll;
    } catch (e) {
      console.log(e);
    }
  },
);

export const getCurrentPublicationThunk = createAsyncThunk(
  'getCurrentPublication',
  async (id: string) => {
    try {
      const { data: currentPublication } = await getCurrentPublication(id);
      return currentPublication;
    } catch (e) {
      console.log(e);
    }
  },
);
export const getAllBooksThunk = createAsyncThunk(
  'getAllBooks',
  async (payload?: TPayload) => {
    try {
      const { data: booksAll } = await getAllBooks(
        payload?.page,
        payload?.type,
      );
      return booksAll;
    } catch (e) {
      console.log(e);
    }
  },
);

import { AxiosRequestConfig } from 'axios';
import { Post } from 'src/axios';
import { TResumeForm } from 'src/types/shared';

export const sendResumeForm = (params: TResumeForm) => {
  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  const formData = new FormData();
  formData.append('name', params.name);
  formData.append('file', params.file);

  return Post<string>('/feedback/cv/', formData, config);
};

import { createSlice } from '@reduxjs/toolkit';
import { TPublication, TPublicationItem } from 'src/types/publication';
import {
  getAllBooksThunk,
  getAllPublicationThunk,
  getCurrentPublicationThunk,
} from './thunk';

const initialState: {
  publicationAll: TPublication | null;
  currentPublication: TPublicationItem | null;
  booksAll: TPublication | null;
  loadingAll: boolean;
  loadingCurrent: boolean;
  langDataPublication: string;
  langDataBook: string;
} = {
  publicationAll: null,
  currentPublication: null,
  booksAll: null,
  loadingAll: false,
  loadingCurrent: false,
  langDataPublication: '',
  langDataBook: '',
};

const publicationSlice = createSlice({
  name: 'publication',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAllPublicationThunk.fulfilled, (state, action) => {
      state.loadingAll = false;
      state.publicationAll = action.payload || null;
      if (action.payload?.results?.length === 0) {
        state.langDataPublication = 'noData';
      }
    });

    builder.addCase(getAllPublicationThunk.pending, (state) => {
      state.loadingAll = true;
      state.langDataPublication = 'noData';
    });

    builder.addCase(getCurrentPublicationThunk.fulfilled, (state, action) => {
      state.loadingCurrent = false;
      state.currentPublication = action.payload || null;
    });

    builder.addCase(getCurrentPublicationThunk.pending, (state) => {
      state.loadingCurrent = true;
    });

    builder.addCase(getAllBooksThunk.fulfilled, (state, action) => {
      state.loadingAll = false;
      state.booksAll = action.payload || null;
      if (action.payload?.results?.length === 0) {
        state.langDataBook = 'noData';
      }
    });

    builder.addCase(getAllBooksThunk.pending, (state) => {
      state.loadingAll = true;
      state.langDataBook = '';
    });
  },
});

export default publicationSlice.reducer;

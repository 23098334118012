import { createSlice } from '@reduxjs/toolkit';
import { TReview, TReviewCategories } from 'src/types/review';
import {
  getCurrentCategoryReviewsThunk,
  getCurrentReviewThunk,
  getReviewListCategoriesThunk,
  getUnlockingReviewsThunk,
} from './thunk';

const initialState: {
  reviewListCategories: TReviewCategories[];
  reviewCurrent: TReview | null;
  loading: boolean;
  langDataReview: string;
  currentCategoryReviews: TReview[];
} = {
  reviewListCategories: [],
  currentCategoryReviews: [],
  reviewCurrent: null,
  loading: false,
  langDataReview: '',
};

const reviewSlice = createSlice({
  name: 'review',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getReviewListCategoriesThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.reviewListCategories = action.payload || [];
      if (action.payload?.length === 0) {
        state.langDataReview = 'noData';
      }
    });
    builder.addCase(getReviewListCategoriesThunk.pending, (state) => {
      state.loading = true;
      state.langDataReview = '';
    });
    builder.addCase(getCurrentReviewThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.reviewCurrent = action.payload;
      }
    });
    builder.addCase(
      getCurrentCategoryReviewsThunk.fulfilled,
      (state, action) => {
        state.loading = false;
        state.currentCategoryReviews = action.payload || [];
        if (action.payload?.length === 0) {
          state.langDataReview = 'noData';
        }
      },
    );
    builder.addCase(getCurrentCategoryReviewsThunk.pending, (state) => {
      state.loading = true;
      state.langDataReview = '';
    });

    builder.addCase(getUnlockingReviewsThunk.fulfilled, (state, action) => {
      state.currentCategoryReviews = action.payload || [];
    });
  },
});

export default reviewSlice.reducer;

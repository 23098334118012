import { createSlice } from '@reduxjs/toolkit';
import { TInformationCompany } from 'src/types/aboutCompany';
import { getInformationCompanyThunk } from './thunk';

const initialState: {
  aboutCompanyInfo: TInformationCompany | null;
  loading: boolean;
} = {
  aboutCompanyInfo: null,
  loading: false,
};

const aboutCompanySlice = createSlice({
  name: 'aboutCompany',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getInformationCompanyThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.aboutCompanyInfo = action.payload || null;
    });

    builder.addCase(getInformationCompanyThunk.pending, (state) => {
      state.loading = true;
    });
  },
});

export default aboutCompanySlice.reducer;

import { useTranslation } from 'react-i18next';
import styles from './languageComponent.module.scss';
// import { useAppDispatch } from 'src/redux/store';
// import { setLaguageThunk } from 'src/redux/staticPages/thunk';
import { useLocation } from 'react-router-dom';

// interface ILanguage {
//   title: string;
//   value: string;
//   code: string;
// }

const LANGUAGE = [
  { title: 'Ru', value: 'ru', code: 'ru' },
  { title: 'En', value: 'en', code: 'en' },
  { title: '中文', value: 'chi', code: 'ch' },
];

const LanguageComponent = () => {
  const { i18n } = useTranslation();
  // const dispatch = useAppDispatch();

  const location = useLocation();
  const loc = window.location;

  const getLanguageLink = (lang: string) => {
    let clearHostname = loc.hostname;

    const splitHostname = loc.hostname.split('.');
    if (splitHostname.length > 2) {
      clearHostname = splitHostname.splice(splitHostname.length - 2).join('.');
    }

    if (loc.hostname.includes('localhost')) {
      clearHostname = 'localhost';
    }

    return `${loc.protocol}//${lang}.${clearHostname}${
      loc.port ? ':' + loc.port : ''
    }${location.pathname}`;
  };

  // const onChangeLanguage = async ({ value: language, code }: ILanguage) => {
  //   try {
  //     await dispatch(setLaguageThunk(code));

  //     localStorage.setItem('lang', language);
  //     i18n.changeLanguage(language);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <div className={styles.language}>
      {LANGUAGE?.map((item) => (
        <a
          // type='button'
          className={
            item.value === i18n.language
              ? `${styles.languageItem} ${styles.languageItemActive}`
              : styles.languageItem
          }
          key={item.value}
          href={getLanguageLink(item.value)}
          // onClick={() => onChangeLanguage(item)}
        >
          {item.title}
        </a>
      ))}
    </div>
  );
};

export default LanguageComponent;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { sendResumeForm } from 'src/api/resume';
import { TResumeForm } from 'src/types/shared';

export const sendResumeFormThunk = createAsyncThunk(
  'sendResumeForm',
  async (params: TResumeForm) => {
    try {
      const { data: sendResume } = await sendResumeForm(params);
      return sendResume;
    } catch (e) {
      throw new Error();
    }
  },
);

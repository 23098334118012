import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyInfo } from 'src/api/aboutCompany';

export const getInformationCompanyThunk = createAsyncThunk(
  'getInformationCompany',
  async () => {
    try {
      const { data: aboutCompanyInfo } = await getCompanyInfo();
      return aboutCompanyInfo;
    } catch (e) {
      console.log(e);
    }
  },
);

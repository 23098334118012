import { createSlice } from '@reduxjs/toolkit';
import { TImagesRating } from 'src/types/rating';
import { TBannerHome } from 'src/types/staticpages';
import { getBannerThunk, getImageRatingThunk, getIsVideoThunk } from './thunk';

const initialState: {
  ratingImageHomeInfo: TImagesRating[];
  isActiveVideo: boolean;
  bannerHome: TBannerHome[];
} = {
  ratingImageHomeInfo: [],
  isActiveVideo: false,
  bannerHome: [],
};

const staticPagesSlice = createSlice({
  name: 'staticPage',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getImageRatingThunk.fulfilled, (state, action) => {
      state.ratingImageHomeInfo = action.payload || [];
    });

    builder.addCase(getIsVideoThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.isActiveVideo = action.payload;
      }
    });

    builder.addCase(getBannerThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.bannerHome = action.payload;
      }
    });
  },
});

export default staticPagesSlice.reducer;
